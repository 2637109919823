import React from 'react'
import { Blob } from 'react-blob'
import './Hero.css';

const Hero = () => {
  return (
    <div className="heroContainer" id='home'>
        <div className="leftHero">
            <p className='yellowFont'>YOUR VISION,</p>
            <p className='yellowFont' style={{marginTop : "-15px"}}>OUR ACTION</p>
            <p className='smallBlocks'>At Spinfluence, we pride ourselves on creating the best stategy for our clients-all thanks to our proven techniques, our motto and our knowledge about social media</p>
            <div className="buttonBlocks">
                <a href="#contact">I AM A INFLUENCER</a>
                <a href="#contact">I AM A BRAND</a>
            </div>
        </div>
        <div className="rightHero">
        <Blob className='blob' style={{
            position: 'absolute',
            backgroundColor :'white',
            width : "100%",
            height : "100%",
            zIndex : -1,
            opacity: 1
        }}></Blob>
            <img src="https://res.cloudinary.com/anshumxn09/image/upload/v1674234035/test/main_zttagh.png" alt="Hero Section Image" width={450} height={450} />
        </div>
    </div>
  )
}

export default Hero;