import React, {useState} from 'react'
import './Navbar.css';

const Navbar = () => {
    const [onHam, setOnHam] = useState(true)
    const [upper, setUpper] = useState("upper")
    const changeIcons = () => {
        setOnHam(!onHam)
        if(!onHam) setUpper('upper');
        else{
            setUpper("")
        }
    }
    const setClose = () => {
        setOnHam(true);
        setUpper("upper");
    }
  return (
    <div className="myNavbar">
        <div className="logoBox">
            <div className="myLogo">
            <img src="https://res.cloudinary.com/anshumxn09/image/upload/v1674239910/test/logo_spinfluence_gjwbn5.png" alt="logo" width={200} height={50}/>
            </div>
        </div>
        <div className="mobileIcons" onClick={changeIcons}>
            {
                onHam ? <i class="fa-solid fa-bars" style={{color : "white"}}/> : <i class="fa-solid fa-xmark" style={{color : "white"}}/>
            }
        </div>
        <div className={`linksBox ${upper}`}>
            <a href="#home" onClick={setClose}>Home</a>
            <a href="#contact" onClick={setClose}>Contact</a>
        </div>
    </div>
  )
}

export default Navbar;