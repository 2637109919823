import React, { useRef } from "react";
import { useState } from "react";
import "./Contact.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import emailjs from 'emailjs-com';

const Contact = () => {
  const form = useRef()
  const [formData, setFormData] = useState({
    myname : "",
    email : "",
    phone : "",
    categ : ""
  })
  const sendEmail = async (event) => {
    event.preventDefault();
    try {
      const {myname, email, phone , categ} = formData;
      if(!myname || !email || !phone || !categ){
        toast.info("kindly fill out the required fields", {
          autoClose : 2000
        })
        return;
      }
      if(isNaN(phone) || phone.length != 10){
        toast.warning("invalid phone number", {
          autoClose : 2000
        })
        return;
      }
      emailjs.sendForm('service_x2crxhi', 'template_itykdh9', form.current, 'V_kdRkn3CXMXLEzS0').then((res) => {
        toast.success("thanks for connecting with us", {
          autoClose : 2000
        })
      }).catch((error) => {
        toast.error("something went wrong", {
          autoClose : 2000
        })
      })
      setFormData({
        myname : "",
        email : "",
        phone : "",
        categ : ""
      })
    } catch (error) {
      console.log(error)
    }
  }
  const changeData = (e) => {
      const {name, value} = e.target;
      setFormData({
        ...formData,
        [name] : value
      })
  }

  return (
    <>
    <div className="contactContainer" id="contact">
      <div className="addressBlock" data-aos="fade-right">
        <p style={{ fontSize: "30px" }}>We'd love to hear from</p>
        <p className="largeMe">YOU</p>
        <p style={{ fontSize: "25px" }}>Address</p>
        <div className="iconsAddress">
          <i class="fa-solid fa-location-dot"></i>
          <p>
            Bhayander East,
            <br />
            Thane - 401105
          </p>
        </div>
        <div className="iconsAddress">
          <i class="fa-solid fa-envelope"></i>
          <p>info@spinfluencemarketing.com</p>
        </div>
        <div className="iconsAddress">
          <i class="fa-solid fa-phone"></i>
          <p>+91 8422005220</p>
        </div>
      </div>
      <div className="formContainer" data-aos="zoom-in-up">
        <form onSubmit={sendEmail} ref={form}>
          <h2 style={{ letterSpacing: "2px" }}>Get In Touch</h2>
          <input type="text" name="myname" id="myname" placeholder="Name*" value={formData.myname}  onChange={(e) => changeData(e)}/>
          <input type="email" name="email" id="email" placeholder="Email*" value={formData.email} onChange={(e) => changeData(e)}/>
          <input type="text" name="phone" id="phone" placeholder="Phone*" value={formData.phone} onChange={(e) => changeData(e)}/>
          <p>Are You a</p>
          <div className="askingTag">
            <div className="radioBlocks">
              <input
                type="radio"
                name="categ"
                id="categ_i"
                value={"Influencer"}
                onChange={(e) => changeData(e)}
              /> 
              <label htmlFor="categ_i"> Influencer</label>
            </div>
            <div className="radioBlocks">
              <input type="radio" name="categ" id="categ_b" value={"Brand"} onChange={(e) => changeData(e)}/>
              <label htmlFor="categ_b"> Brand</label>
            </div>
          </div>
          <input type="submit" value="SUBMIT"/>
        </form>
      </div>
    </div>
    <ToastContainer/>
    </>
  );
};

export default Contact;
