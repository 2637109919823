import React from 'react'
import Clients from './components/Clients';
import Contact from './components/Contact';
import Footer from './components/Footer';
import Hero from './components/Hero';
import MoveToTop from './components/MoveToTop';
import Navbar from './components/Navbar';
import WhatWeDo from './components/WhatWeDo';
import Working from './components/Working';

const App = () => {
  return (
    <>
      <Navbar/>
      <Hero/>
      <Clients/>
      <WhatWeDo/>
      <Working/>
      <Contact/> 
      <Footer/> 
      <MoveToTop/>
    </>
  )
}

export default App;