import React, { useRef } from "react";
import "./Clients.css";
import { Carousel } from "antd";


const Clients = () => {
  const ref = useRef();

  return (
    <div className="clContainer">
      <h2
        style={{
          fontSize: "35px",
          color: "yellow",
          letterSpacing: "2px",
          textAlign: "center",
          textShadow: "2px 1px 6px rgba(0,0,0,0.6)",
        }}
      >
        Our Clients
      </h2>
      <div className="carousel">
      <div className="left" onClick={() => {
        ref.current.prev()
      }}><i class="fa-solid fa-chevron-left" style={{color : '#444be9'}}></i></div>
      <div className="right" onClick={() => {
        ref.current.next()
      }}><i class="fa-solid fa-chevron-right" style={{color : '#444be9'}}></i></div>
        <Carousel autoplay="true" draggable dots={false} ref={ref}>
          <img src="https://res.cloudinary.com/anshumxn09/image/upload/v1675107194/Spinfluence/gopaisa_logo_rnfael.png" alt="1" style={{objectFit : 'cover'}} height={90} width={"80%"}/>
          <img src="https://res.cloudinary.com/anshumxn09/image/upload/v1675107400/Spinfluence/hirect_logo_gbdcd6.png" alt="1" style={{objectFit : 'cover'}}/>
          <img src="https://res.cloudinary.com/anshumxn09/image/upload/v1675107476/Spinfluence/relevel_logo_nuzrja.png" alt="1" style={{objectFit : 'cover'}}/>
          <img src="https://res.cloudinary.com/anshumxn09/image/upload/v1675107528/Spinfluence/winzo_logo_wuermw.png" alt="1" style={{objectFit : 'cover'}} height={90} width={"80%"}/>
          <img src="https://res.cloudinary.com/anshumxn09/image/upload/v1675105247/Spinfluence/binomo_att5d2.png" alt="1" style={{objectFit : 'cover'}}/>
        </Carousel>
      </div>  
    </div>
  );
};

{/* <Carousel autoplay="true" draggable>
          <img src="https://res.cloudinary.com/anshumxn09/image/upload/v1675105247/Spinfluence/binomo_att5d2.png" alt="1" height={200}/>
          <h1>Anshuman</h1>
          <h1>Anshuman</h1>
          <h1>Anshuman</h1>
        </Carousel> */}

export default Clients;
