import React from 'react'
import "./Working.css";

const Working = () => {
  return (
    <div className="wContainer">
        <h2 style={{fontSize : "35px", color : "yellow", letterSpacing : "2px", textAlign : "center"}}>Infleuncers Working With Us</h2>
        <img src="https://res.cloudinary.com/anshumxn09/image/upload/v1675101542/Spinfluence/Influencers-collage_pimh7d.png" alt="Collaboration" width={"90%"} height = {"100%"} style={{margin : "10px 0"}} className="myImage" data-aos="zoom-in-up"/>
        <div className="empty">
        <div className="phoneBlocks" data-aos="zoom-in-up">
            <img src="https://res.cloudinary.com/anshumxn09/image/upload/v1675102166/Spinfluence/collage1.pn2_sr2sbo.jpg" alt="1" width={300} height={300}/>
            <img src="https://res.cloudinary.com/anshumxn09/image/upload/v1675102136/Spinfluence/collage1_cupm7g.jpg" alt="2" width={300} height={300}/>
        </div>
        </div>
        <h2 style={{fontSize : "35px", color : "yellow", letterSpacing : "2px", textAlign : "center", marginTop : "10px"}}>Platform we Operate in</h2>
        <div className="logoWork">
            <i class="fa-brands fa-youtube"></i>
            <i class="fa-brands fa-facebook"></i>
            <i class="fa-brands fa-instagram"></i>
        </div>
        <p style={{textAlign : 'center', color : 'white', fontSize  : "15px"}}>Each platform having it's own unique demographics and analytics. We have a huge amount of infleuncers in above mentioned platforms with unique audiences</p>
    </div>
  )
}

export default Working