import React, { useState } from 'react'
import "./WhatWeDo.css";

const WhatWeDo = () => {
    const [content] = useState([
        {
            title : "Youtube Influencer Campaigns",
            content : "Influence your buyer's persona on Youtube through top Youtube Content Creators"
        },
        {
            title : "Instagram Influencer Campaigns",
            content : "Inspire your prospects on Instagram through top Instagram Creators"
        },
        {
            title : "End To End Campaign Execution",
            content : ""
        }
    ])
  return (
    <div className="wContainer">
        <div className="cwContainer" data-aos="fade-right">
        <p style={{fontSize : "20px", color : "white", marginTop : "10px"}}>We Are</p>
      <h2
        style={{
          fontSize: "40px",
          color: "yellow",
          letterSpacing: "2px",
          textShadow: "2px 1px 6px rgba(0,0,0,0.6)",
          textTransform : 'uppercase'
        }}
      >
       Spinfluence
      </h2>
      <p style={{fontSize : "20px", color : "white", marginBottom : "15px"}}>We are an infleuncer Marketing Agency in India</p>
            <h2 style={{fontSize : "35px", color : "yellow", letterSpacing : "2px", textShadow : '2px 1px 6px rgba(0,0,0,0.6)'}}>What We Do</h2>
            {
                content.map((elem, index) => {
                    return (
                        <ul style={{marginLeft : "30px", marginTop : "10px"}} key={index}>
                            <li style={{fontSize : "25px", fontWeight : "1000", color : "white", letterSpacing : "2px"}}>{elem.title}</li>
                            <p style={{color : "white"}}>{elem.content}</p>
                        </ul>
                    )
                })
            }
        </div>
    </div>
  )
}

export default WhatWeDo