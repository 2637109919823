import React, { useEffect, useState } from 'react'

const MoveToTop = () => {
    const [hidden, setHidden] = useState("")
    const [showMe, setShowMe] = useState(false);
    const moveToTop = () => {
        window.scrollTo({
            top :0,
            left : 0,
            behavior : 'smooth'
        })
    }

    const scrollingFunction = () => {
        let hiddenHeight = 0;
        const getScroll = document.body.scrollTop || document.documentElement.scrollTop;
        if(getScroll > hiddenHeight) {
            setShowMe(true)
            setHidden("")
        }else {
            setShowMe(false)
            setHidden("hiddenTop")
        }
    }
    useEffect(() => {
        window.addEventListener('scroll', scrollingFunction)
    })
  return (
    <>
    {
        showMe && <div className={`moveToTop ${hidden}`} onClick={moveToTop}style={{color : "#444be9", fontSize: "25px"}}><i className="fa-solid fa-arrow-up" /></div>
    }
    </>
  )
}

export default MoveToTop