import React from "react";
import "./Footer.css";

const Footer = () => {
  return (
    <>
      <div className="footerContainer">
        <div className="leftFooter" data-aos="fade-right">
          <img src="https://res.cloudinary.com/anshumxn09/image/upload/v1674230854/test/logo_wm5zsl.png" alt="logo" width={370} height={100}/>
          <p style={{fontSize : "30px", letterSpacing : "2px"}}>Contact</p>
          <div className="iconsAddress">
            <i class="fa-solid fa-envelope"></i>
            <p>info@spinfluencemarketing.com</p>
          </div>
          <div className="iconsAddress">
            <i class="fa-solid fa-phone"></i>
            <p>+91 8422005220</p>
          </div>
        </div>
        <div className="rightFooter" data-aos="zoom-in-up">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d30123.407667682823!2d72.84784188282843!3d19.307314117352565!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7b02921c6677b%3A0xb164cc17e553e965!2sBhayandar%2C%20Bhayandar%20East%2C%20Mira%20Bhayandar%2C%20Maharashtra!5e0!3m2!1sen!2sin!4v1674226237093!5m2!1sen!2sin"
            width="350"
            height="300"
            style={{ border: 0 }}
            loading="lazy"
            referrerPolicy="no-referrer-when-downgrade"
          />
        </div>
      </div>
      <div className="copyRight">
        <p>&copy; Copyright Spinfluence Marketing 2023-24 | Developed By Anshuman Sharma</p>
      </div>
    </>
  );
};

export default Footer;
